import React from 'react';
import { useEffectOnce } from 'react-use';
import { motion } from 'framer-motion';

import cn from '@utils/helpers/cn';
import PageTitle from '@components/page/PageTitle';
import PageHeader from '@components/page/PageHeader';
import PageContent from '@components/page/PageContent';

const defaultConfig = {
  displayPageHeader: true,
  isAlignItemsStart: true,
  hasTextCentered: false,
  titleSize: 'md',
};

export default function Page(props) {
  const {
    className,
    noContentTitle,
    noContentMessage,
  } = props;

  const config = { ...defaultConfig, ...props.config };

  const isLoading = props.isLoading ?? false;
  const displayContent = props.displayContent ?? true;

  useEffectOnce(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  });

  const sectionClassNames = cn([
    'px-4',
    'pt-8',
    'pb-24',
    'md:px-8',
    'min-h-screen',
  ], {
    'content-start': config.isAlignItemsStart,
    'content-center': !config.isAlignItemsStart,
    'text-center': config.hasTextCentered,
  });

  const columnClassNames = cn(className);

  /**************************************************************************************************/

  return (
    <section className={sectionClassNames}>
      <motion.div
        key="page"
        className="container mx-auto"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          duration: 0.5,
          ease: 'easeOut',
          delay: 0.5,
        }}
        layout="position"
      >
        <div className="grid grid-cols-12 justify-center gap-4">
          <div className={columnClassNames}>
            {config.displayPageHeader && (
              <PageHeader />
            )}

            <PageTitle
              value={config.title}
              size={config.titleSize}
            />

            <PageContent
              isLoading={isLoading}
              displayContent={displayContent}
              noContentTitle={noContentTitle}
              noContentMessage={noContentMessage}
            >
              {props.children}
            </PageContent>
          </div>
        </div>
      </motion.div>
    </section>
  );
}
