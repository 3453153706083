/***************************************************************************************************
** getProps
***************************************************************************************************/

export default function getProps(props, defaultProps = {}) {
  const result = {};

  for (const key in defaultProps) {
    result[key] = props[key] ?? defaultProps[key];
  }

  return result;
};
