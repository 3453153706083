import React from 'react';
import { motion } from 'framer-motion';

import cn from '@utils/helpers/cn';
import { useWebsiteContext } from '@contexts/WebsiteContext';

const THEME_COLOR = '#C68526';

const PageHeaderContent = (props) => {
  const { value } = props;

  if (!value.includes('&')) return value;

  const [name1, name2] = value.split('&');

  return (
    <>
      {name1}
      <span className="mx-[0.1rem] text-[color:var(--page-header-ampersand-color)]">
        &
      </span>
      {name2}
    </>
  );
};

export default function PageHeader(props) {
  const { value } = props;

  const { parameters } = useWebsiteContext();

  const displayValue = React.useMemo(() => {
    return value || parameters.pageHeader;
  }, [value, parameters.pageHeader]);

  const headerClassNames = cn([
    'py-1',
    'px-2',
    'rounded-[0.3rem]',
    'border-solid',
    'border-[0.0625rem]',

    'text-[0.75rem]',
    'font-normal',
    'tracking-[0.05rem]',
    'leading-normal',
    'uppercase',

    'bg-page-header-bg',
    'border-page-header-border',
    'text-page-header-text',
  ]);

  const styles = {
    color: THEME_COLOR,
    borderColor: THEME_COLOR,
    backgroundColor: 'transparent',
    boxShadow: `0 0 1rem ${THEME_COLOR}66, 0 0 3rem ${THEME_COLOR}22`,
  };

  /**************************************************************************************************/

  if (!displayValue) return null;

  return (
    <motion.div
      key="page-header"
      className="flex justify-center"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        delay: 0.5,
        type: 'spring',
        stiffness: 100,
        damping: 10,
      }}
      layout={true}
    >
      <motion.span
        className={headerClassNames}
        style={styles}
      >
        <PageHeaderContent value={displayValue} />
      </motion.span>
    </motion.div>
  );
}
