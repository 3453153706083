import React from 'react';
import { motion } from 'framer-motion';

import cn from '@utils/helpers/cn';

const variants = {
  hidden: {
    opacity: 0,
    scale: 1.1,
    transition: {
      delay: 0,
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      duration: 0.5,
      ease: 'easeOut',
    },
  },
};

const containerStyles = {
  marginBottom: 'var(--loader-margin-bottom)',
  marginLeft: 'var(--loader-margin-left)',
  marginRight: 'var(--loader-margin-right)',
  marginTop: 'var(--loader-margin-top)',
  padding: 'var(--loader-padding)',
};

const containerClassNames = cn([
  'relative',
]);

const classNames = cn([
  'animate-spin',
  'rounded-full',
  'block',
  'relative',
  'opacity-100',
]);

export default function CircleLoader(props) {
  const {
    size = 'min(15vw, 15vh)',
    padding = '1rem',
    marginBottom = '0',
    marginLeft = '0',
    marginRight = '0',
    marginTop = '0',
    opacity = '1',
  } = props;

  const containerProperties = {
    '--loader-margin-bottom': marginBottom,
    '--loader-margin-left': marginLeft,
    '--loader-margin-right': marginRight,
    '--loader-margin-top': marginTop,
    '--loader-padding': padding,
    '--loader-size': size,
  };

  const properties = {
    '--loader-color': '#DBDBDB59',
  };

  // TODO=Styles
  const styles = {
    borderBottomColor: 'var(--loader-color)',
    borderLeftColor: 'var(--loader-color)',
    height: 'var(--loader-size)',
    minHeight: 'min(150px, var(--loader-size))',
    minWidth: 'min(150px, var(--loader-size))',
    width: 'var(--loader-size)',
    background: 'radial-gradient(farthest-side, #D7D7D7 94%, #00000000) top/9px 9px no-repeat, conic-gradient(#00000000 30%, #D7D7D7)',
    WebkitMask: 'radial-gradient(farthest-side, #00000000 calc(100% - 9px), #000000 0)',
    opacity: opacity,
  };

  return (
    <motion.div
      key="loader"
      className={containerClassNames}
      style={{
        ...containerProperties,
        ...containerStyles,
      }}
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      layout={true}
    >
      <div
        className={classNames}
        style={{
          ...properties,
          ...styles,
        }}
      />
    </motion.div>
  );
}
