/***************************************************************************************************
** keysToCamelCase
***************************************************************************************************/

const snakeToCamelCase = (string) => string.replace(/(_\w)/g, (letter) => letter[1].toUpperCase());

const transformObjectDeep = (object, keyValueTransformer, objectPreProcessor = undefined) => {
  if (objectPreProcessor && typeof objectPreProcessor === 'function') {
    object = objectPreProcessor(object);
  }

  if (Array.isArray(object)) {
    return object.map((obj) => transformObjectDeep(obj, keyValueTransformer, objectPreProcessor));
  } else if (object === null || typeof object !== 'object') {
    return object;
  }

  return Object.fromEntries(Object.entries(object).map(([key, value]) => keyValueTransformer(key, transformObjectDeep(value, keyValueTransformer, objectPreProcessor))));
};

export default function keysToCamelCase(object) {
  return transformObjectDeep(object, (key, value) => [snakeToCamelCase(key), value]);
}
