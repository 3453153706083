/***************************************************************************************************
** WebsiteContext
***************************************************************************************************/

import React from 'react';

import useKinnFetch from '@hooks/useKinnFetch';

/**************************************************************************************************/

export const WebsiteContext = React.createContext();

export const WebsiteContextProvider = (props) => {
  const [website, setWebsite] = React.useState({
    id: window.appContext.websiteId,
    parameters: {},
  });

  const { data, status } = useKinnFetch(['websites', window.appContext.websiteId]);

  React.useEffect(() => {
    if (status === 'fetched') {
      setWebsite({
        ...data,
        parameters: data.parameters || {},
      });
    }
  }, [status, data]);

  return (
    <WebsiteContext.Provider value={website}>
      {props.children}
    </WebsiteContext.Provider>
  );
};

/**************************************************************************************************/

export const useWebsiteContext = () => {
  const context = React.useContext(WebsiteContext);

  if (!context) {
    throw new Error('WebsiteContext must be used within a WebsiteContextProvider');
  }

  return context;
};
