import React from 'react';

import cn from '@utils/helpers/cn';

// const STYLES = {
//   background: 'linear-gradient(0deg,rgba(186,207,247,.04),rgba(186,207,247,.04)),rgba(5,6,15,.97)',
//   boxShadow: 'inset 0 1px 1px 0 rgba(216,236,248,.2),inset 0 24px 48px 0 rgba(168,216,245,.06),0 16px 32px rgba(0,0,0,.3)',
//   backgroundClip: 'content-box',
//   borderRadius: '16px',
// }

import './Card.scss';

const CARD_CONTAINER_CLASS_NAMES = cn([
  'relative',
  'overflow-hidden',
  'rounded-2xl',
  'bg-gradient-to-b',
  'from-neutral-700',
  'to-neutral-800',
  'max-w-full',
  'max-h-full',
  'shadow-xl',
  'shadow-black/30',

  'before:bg-[conic-gradient(rgba(225,170,0,0)_0deg,_rgba(225,170,0,0.5)_0deg,transparent_30deg)]',
  'before:absolute',
  'before:-left-1/2',
  'before:-top-1/2',
  'before:h-[200%]',
  'before:w-[200%]',
  'before:animate-borderSpin',
  'before:content-[\'\']',
]);

const CLASS_NAMES = cn([
  'card',

  'block',
  'relative',
  'max-w-full',
  'mx-0',
  'z-50',
  'p-5',

  'scroll-m-4',
  'break-inside-avoid',
  'tap-highlight-transparent',

  'rounded-2xl',
  'border',
  'border-solid',
  'shadow-xl',
  'shadow-black/30',

  'transition-all',
  'duration-250',
  'ease-in-out',

  'text-card-default-text',
  'bg-card-default-bg',
  'border-card-default-border',
]);

const STYLES = {
  backgroundImage: `
    radial-gradient(120% 100% at 30% 0, rgba(65, 65, 65, 0.5) 0, rgba(65, 65, 65, 0.20) 30%, rgba(60, 60, 60, 0) 50%),
    radial-gradient(90% 45% at 50% 0, rgba(25, 25, 25, 0.5) 20%, rgba(25, 25, 25, 0) 100%),
    radial-gradient(70% 25% at 50% 100%, rgb(20, 20, 20, 0.5) 25%, rgba(15, 15, 15, 0) 100%)
  `,
};

const CardContainer = (props) => {
  const {
    className,
    style,
    children,
    ...otherProps
  } = props;

  return (
    <div className={CARD_CONTAINER_CLASS_NAMES}>
      <div
        className={cn(CLASS_NAMES, className)}
        style={{
          ...STYLES,
          ...style,
        }}
        {...otherProps}
      >
        {children}
      </div>
    </div>
  );
};

const headerClassNames = cn([
  'relative',
  'h-48',
  'w-full',
  'overflow-hidden',
  'bg-cover',
  'bg-center',
  'text-center',

  'rounded-t-2xl',

  'flex',
  'items-end',
  'justify-start',
  'font-serif',
  'capitalize',
  'tracking-[1px]',
  '[text-shadow:1px_1px_7px_black]',
  'text-[0.5rem]',
]);

export default function Card(props) {
  const {
    className,
    style,
    content,
    children,

    isHoverable,
    headerImageUrl,
    ...otherProps
  } = props;

  const classNames = cn([
    className,
  ], {
    'hover:text-card-hover-text': isHoverable,
    'hover:bg-card-hover-bg': isHoverable,
    'hover:border-card-hover-border': isHoverable,
  });

  if (!content && !children) return null;

  return (
    <CardContainer
      className={classNames}
      style={style}
      {...otherProps}
    >
      {headerImageUrl && (
        <div
          className={headerClassNames}
          style={{
            // backgroundImage: `linear-gradient(90deg, #FFFFFF44 3%, #FFFFFF00 50%, transparent), linear-gradient(0deg, #FFFFFF00, #FFFFFF22), url(${headerImageUrl})`,
            backgroundImage: `url(${headerImageUrl})`,
          }}
        />
      )}
      <div className="px-6 pb-10 pt-8 md:px-8 md:pb-8 md:pt-6">
        {content ? <span dangerouslySetInnerHTML={{ __html: content }} /> : children}
      </div>
    </CardContainer>
  );
}
