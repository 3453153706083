/***************************************************************************************************
** useKinnFetch
***************************************************************************************************/

import React from 'react';

import keysToCamelCase from '@utils/helpers/object/keysToCamelCase';

const FETCHING = Symbol('FETCHING');
const FETCHED = Symbol('FETCHED');
const FETCH_ERROR = Symbol('FETCH_ERROR');

const INVALID_PATH_SEGMENTS = [
  undefined,
  'undefined',
  null,
  'null',
  '',
];

const initialState = {
  status: 'idle',
  error: null,
  data: [],
};

export default function useKinnFetch(pathSegments = []) {
  const url = React.useMemo(() => {
    const hasInvalidSegments = pathSegments.some((segment) => INVALID_PATH_SEGMENTS.includes(segment));
    if (hasInvalidSegments) return null;

    const baseUrl = `https://${window.appContext.kinndomKey}.kinn.app/api`;
    const path = pathSegments.join('/');

    return `${baseUrl}/${path}`;
  }, [pathSegments]);

  const dataCache = React.useRef({});

  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
    case FETCHING:
      return {
        ...initialState,
        status: 'fetching',
      };
    case FETCHED:
      return {
        ...initialState,
        status: 'fetched',
        data: action.payload,
      };
    case FETCH_ERROR:
      return {
        ...initialState,
        status: 'error',
        error: action.payload,
      };
    default:
      return state;
    }
  }, initialState);

  /***************************************************************************************************
  ** __fetchData
  ***************************************************************************************************/

  const __fetchData = React.useCallback(async (abortSignal) => {
    if (!url) return;

    dispatch({ type: FETCHING });

    if (dataCache.current[url]) {
      const data = dataCache.current[url];
      dispatch({ type: FETCHED, payload: data });
    } else {
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Cache-Control': 'no-cache, no-store',
            'Authorization-Token': window.appContext.authorizationToken,
          },
          signal: abortSignal,
        });

        if (!response.ok) throw new Error(response.statusText);

        const data = await response.json()
          .then((body) => body.records)
          .then((records) => keysToCamelCase(records));

        dataCache.current[url] = data;

        dispatch({ type: FETCHED, payload: data });
      } catch (error) {
        if (error.name === 'AbortError') return;

        dispatch({ type: FETCH_ERROR, payload: error.message });
      }
    }
  }, [url]);

  /***************************************************************************************************
  ** fetch
  ***************************************************************************************************/

  const fetchData = React.useCallback(() => {
    const abortController = new AbortController();
    __fetchData(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [__fetchData]);

  /***************************************************************************************************
  ** refetch
  ***************************************************************************************************/

  const refetchData = React.useCallback(() => {
    dataCache.current[url] = undefined;

    const abortController = new AbortController();
    __fetchData(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [url, __fetchData]);

  /***************************************************************************************************
  ** Run fetchData on mount
  ***************************************************************************************************/

  React.useEffect(() => {
    const abortController = new AbortController();
    __fetchData(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [__fetchData]);

  /**************************************************************************************************/

  return {
    ...state,
    fetch: fetchData,
    refetch: refetchData,
  };
}
