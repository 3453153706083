import React from 'react';
import { motion } from 'framer-motion';

const MotionContainerDiv = (props) => {
  const {
    motionKey = 'container',
  } = props;

  return (
    <motion.div
      key={motionKey}
      className="space-y-6"
      initial={{
        y: 0,
      }}
      animate={{
        y: 0,
        transition: {
          staggerChildren: 0.35,
        },
      }}
      exit={{
        y: 0,
      }}
      layout={true}
    >
      {props.children}
    </motion.div>
  );
};

const MotionCardDiv = (props) => {
  const {
    className,
    motionKey = 'card',
    delay = 0.8,
    additionalDelay = 0,
    delayFn,
    index,
  } = props;

  const transitionDelay = delayFn ? delayFn(index) : delay + additionalDelay;

  return (
    <motion.div
      key={motionKey}
      className={className}
      initial={{
        y: 200,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
      }}
      exit={{
        y: 200,
        opacity: 0,
      }}
      transition={{
        opacity: {
          duration: 0.5,
          ease: 'easeOut',
          delay: transitionDelay,
        },
        y: {
          type: 'spring',
          stiffness: 300,
          damping: 20,
          delay: transitionDelay,
        },
      }}
      layout={true}
    >
      {props.children}
    </motion.div>
  );
};

export default function MotionDiv(props) {
  const { type } = props;

  switch (type) {
  case 'container':
    return (
      <MotionContainerDiv {...props} />
    );
  case 'card':
    return (
      <MotionCardDiv {...props} />
    );
  }
}
