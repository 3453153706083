/***************************************************************************************************
** logError
***************************************************************************************************/

export default async function logError(error) {
  if (!error) return;
  if (!window.appContext) throw new Error('App context is missing!');

  /**************************************************************************************************/

  try {
    const response = await fetch('/logs', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Cache-Control': 'no-cache, no-store',
        'Authorization-Token': window.appContext.authorizationToken,
        'X-CSRF-Token': window.appContext.authenticityToken,
      }),
      body: JSON.stringify({
        content: error.toString(),
      }),
    });

    if (!response.ok) throw new Error(response.statusText);

    return true;
  } catch (error) {
    console.error('Error logging:', error);
    return false;
  }
}
