import React from 'react';
import { motion } from 'framer-motion';

import cn from '@utils/helpers/cn';
import getProps from '@utils/helpers/getProps';

// const THEME_COLOR = '#C68526';

export default function PageTitle(props) {
  const {
    value,
    size = 'md',
  } = props;

  const {
    hasDivider,
  } = getProps(props, {
    hasDivider: true,
  });

  const classNames = cn([
    'm-0',
    // 'bg-[url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif)]',
    // 'bg-cover',
    // 'text-transparent',
    // 'bg-clip-text',
    'text-center',
    'font-serif',
    'text-[2.75rem]',
    'leading-normal',
    'tracking-[0.5px]',
    'md:text-[3.25rem]',

    'text-transparent',
    'bg-clip-text',
    'bg-300%',
    'animate-gradient',

    'bg-gradient-to-b',
    'from-[#C68526]',
    'to-[#644800]',
  ], {
    'text-[5rem] md:text-[7.5rem] italic leading-[1.1]': (size === 'lg'),
    'text-[2rem] md:text-[2.75rem] tracking-[0.75px]': (size === 'sm'),
  });

  // TODO=Styles
  const styles = {
    // background: `linear-gradient(${THEME_COLOR} 0%, #644800 100%)`,
    // WebkitTextFillColor: 'transparent',
    WebkitTextStrokeWidth: '1px',
    WebkitTextStrokeColor: '#AE7924',
    // WebkitBackgroundClip: 'text',
  };

  /**************************************************************************************************/

  if (!value) return null;

  return (
    <>
      <motion.div
        key="page-title"
        initial={{
          opacity: 0,
          scale: 0.75,
        }}
        animate={{
          opacity: 1,
          scale: 1.0,
        }}
        transition={{
          type: 'spring',
          stiffness: 100,
          damping: 10,
          delay: 0.4,
        }}
        layout={true}
      >
        <motion.h1
          className={classNames}
          style={styles}
        >
          {value}
        </motion.h1>
      </motion.div>

      {hasDivider && (
        <motion.div
          key="page-title-divider"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.75,
            delay: 0.4,
            ease: 'easeOut',
          }}
          layout={true}
        >
          <hr className="my-4" />
        </motion.div>
      )}
    </>
  );
}
