/***************************************************************************************************
** deepFreezeObject
***************************************************************************************************/

export default function deepFreezeObject(object) {
  if (object && typeof object === 'object' && !Object.isFrozen(object)) {
    Object.keys(object).forEach((property) => deepFreezeObject(object[property]));
    Object.freeze(object);
  }

  return object;
}
